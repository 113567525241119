export const chipSets = [
  'WS2811',
  'WS2801',
  'WS2803',
  'APA102',
  'APA104',
  'APA106',
  'DOTSTAR',
  'GE8822',
  'GS1903',
  'GW6205',
  'GW6205_400',
  'LPD1886',
  'LPD1886_8BIT',
  'LPD6803',
  'LPD8806',
  'P9813',
  'PL9823',
  'SK6812',
  'SK6822',
  'SK9822',
  'SM16703',
  'SM16716',
  'TM1803',
  'TM1804',
  'TM1809',
  'TM1812',
  'TM1829',
  'UCS1903',
  'UCS1903B',
  'UCS1904',
  'UCS2903',
  'WS2811_400',
  'WS2812',
  'WS2812B',
  'WS2813',
  'WS2852',
];

export const rgbOptions = ['RGB', 'GRB', 'RBG', 'GBR', 'BRG', 'BGR'];
export const arduinoUnoPins = [
  '3',
  '5',
  '6',
  '9',
  '10',
  '11',
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
];
export const arduinoMegaPins = [
  '4',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '46',
  '45',
  '44',
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
  'A14',
  'A15',
];
