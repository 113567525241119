export const ACTION_TYPES = Object.freeze({
  CHANGE_NUMBER_LEDS: 'CHANGE_NUMBER_LEDS',
  CHANGE_TOTAL_STEPS: 'CHANGE_TOTAL_STEPS',
  CHANGE_SELECTED_COLOR_LEDS: 'CHANGE_SELECTED_COLOR_LEDS',
  CHANGE_TIME_PER_STEP: 'CHANGE_TIME_PER_STEP',
  SELECT_LED: 'SELECT_LED',
  UN_SELECT_LED: 'UN_SELECT_LED',
  MOVE_PLAYER_FORWARD: 'MOVE_PLAYER_FORWARD',
  MOVE_PLAYER_BACKWARD: 'MOVE_PLAYER_BACKWARD',
  CHANGE_POSITION_PLAYER: 'CHANGE_POSITION_PLAYER',
  RUN_SIMULATION: 'RUN_SIMULATION',
  STOP_SIMULATION: 'STOP_SIMULATION',
  CHANGE_DRAG_MODE: 'CHANGE_DRAG_MODE',
  LED_DRAG_MODE: 'LED_DRAG_MODE',
  SELECTION_MODE: 'SELECTION_MODE',
  MOUSE_DRAG_ON: 'MOUSE_DRAG_ON',
  MOUSE_DRAG_OFF: 'MOUSE_DRAG_OFF',
  RESIZE_PIXELS: 'RESIZE_PIXELS',
  GENERATE_PATTERN: 'GENERATE_PATTERN',
  CHANGE_RGB_ORDER: 'CHANGE_RGB_ORDER',
  CHANGE_CHIPSET: 'CHANGE_CHIPSET',
  CHANGE_MICROCONTROLLER: 'CHANGE_MICROCONTROLLER',
  CHANGE_ANALOG_PIN: 'CHANGE_ANALOG_PIN',
  CHANGE_BRIGHTNESS_LEVEL: 'CHANGE_BRIGHTNESS_LEVEL',
  OPEN_NEW_PROGRAM: 'OPEN_NEW_PROGRAM',
  ADD_FRAMES_TO_LOOP: 'ADD_FRAMES_TO_LOOP',
  ON_UPLOADING_CODE: 'ON_UPLOADING_CODE',
  STOP_UPLOADING_CODE: 'STOP_UPLOADING_CODE',
  ON_COMPILE_CODE: 'ON_COMPILE_CODE',
  STOP_COMPILE_CODE: 'STOP_COMPILE_CODE',
  SET_PATTERN: 'SET_PATTERN',
  NEW_PROJECT: 'NEW_PROJECT',
  SET_SAVED_STATE: 'SET_SAVED_STATE',
  CHANGE_PROJECT_NAME: 'CHANGE_PROJECT_NAME',
  CHANGE_PROJECT_DESCRIPTION: 'CHANGE_PROJECT_DESCRIPTION',
  SET_FIREBASE_ID: 'SET_FIREBASE_ID',
  SET_SAVING: 'SET_SAVING',
  SET_PUBLISHED: 'SET_PUBLISHED',
});
